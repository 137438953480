import React, { useEffect, useState } from "react";
import "./App.css";
import { adminRoutes, developerRoutes, unauthorizedRoutes, plantManagerRoutes } from "./routes";
import { useRoutes } from "react-router-dom";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import CustomizedSnackbars from "./componets/Alert/SnackBar";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import { loadSeed } from "./Redux/Seed/SeedSlice";
import { loadAlias } from "./Redux/Alias/AliasSlice";
import { loadVisuals } from "./Redux/Visuals/VisualsSlice";
import { loadKPI } from "./Redux/KPIs/KPISlice";
import { loadDashboards } from "./Redux/Dashboards/DashboardsSlice";
import { loadUsecase } from "./Redux/UseCase/UseCaseSlice";
import { loadAssetTypes } from "./Redux/AssetTypes/AssetTypesSlice";
import { loadAssets } from "./Redux/Assets/AssetsSlice";
import { loadPlants } from "./Redux/Plants/PlantsSlice";
import { loadSensors } from "./Redux/Sensors/SensorsSlice";
import { loadDevices } from "./Redux/Devices/DevicesSlice";
import { loadLevelTypes } from "./Redux/LevelTypes/LevelTypesSlice";
import { loadOEMs } from "./Redux/OEMs/OEMSlice";
import { loadModels } from "./Redux/Models/ModelSlice";
import { findDatasInSeed } from "./utils/helper-functions/functions";
import {
  CUBE_ACCESS_TOKEN,
  CUBE_API_URL,
  CUBE_CONFIGURATION,
} from "./utils/constants";
import ThemeProvider from "./theme/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import SessionTimeout from "./Components/Common/SessionTimeout";
import { loadCurrentUserImage } from "./Redux/UserPreference/UsersSlice";
import { loadArchUsers, loadCurrentUser } from "./Redux/Users/ARCHUsersSlice";
import { useMsal } from "@azure/msal-react";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "./config/axiosConfigArch";
import { env } from "./env";
export const AppContext: any = React.createContext(null); //creating app context to share throughout app

const App = () => {
  const contentAdmin = useRoutes(adminRoutes);
  const contentDeveloper = useRoutes(developerRoutes);
  const contentUnauthorized = useRoutes(unauthorizedRoutes);
  const contentPlantManager = useRoutes(plantManagerRoutes);
  const snackBarState = useSelector((state: any) => state.Alert);
  const seedState = useSelector((state: any) => state.seedList);
  const archUsersList = useSelector((state: any) => state.archUsersList);
  const usersList = useSelector((state: any) => state.usersList);
  const plantsList = useSelector((state: any) => state.plantsList);
  const dispatch = useDispatch();
  const [cubejsApi, setCubejsApi] = useState<any>(null);
  const [admins, setAdmins] = useState([]);
  const { accounts, instance } = useMsal();
  const [selectedPlantForCube, setSelectedPlantForCube] =
    React.useState<any>("ATL"); //default plant for fectching token

  const user = {
    name: accounts[0]?.name,
    avatar: "",
    mailId: accounts[0]?.username,
    jobtitle: "",
  };
  useEffect(() => {
    if (selectedPlantForCube !== "" && selectedPlantForCube !== undefined) {
      Axios.post("plants/cubeaccesstoken", {
        plant_code: selectedPlantForCube,
      }).then((res: any) => {
        const cubejsApi = cubejs(res.data.cube_access_token, {
          apiUrl: env.CUBE_API_URL!,
        });
        //updating cubejs api state with new token
        setCubejsApi(cubejsApi);
        return res;
      });
    }
  }, [selectedPlantForCube]);

  useEffect(() => {
    if (seedState.data.length === 0) {
      // Dispatch call to set Seed Data to reducer
      dispatch(loadSeed());
    }
    if (usersList.currentUserImage === null) {
      // Dispatch call to set current user image to reducer
      dispatch(loadCurrentUserImage());
    }
    dispatch(loadAssets());
    dispatch(loadArchUsers());
    dispatch(loadAlias());
    dispatch(loadVisuals());
    dispatch(loadKPI());
    dispatch(loadDashboards());
    dispatch(loadUsecase());
    dispatch(loadDevices());
    dispatch(loadSensors());
    dispatch(loadPlants());
    dispatch(loadAssetTypes());
    dispatch(loadLevelTypes());
    dispatch(loadOEMs());
    dispatch(loadModels());
  }, []);
  useEffect(() => {
    if (
      archUsersList?.archRoles.length > 0 &&
      archUsersList?.archUsers.length > 0 &&
      plantsList?.data.length > 0
    ) {
      setAdmins(
        archUsersList.archUsers.filter((user: any) => {
          return user.user_arch_group.includes(
            archUsersList.archRoles.find((role: any) => {
              return role.role_name === "ARCH_ADMIN" ||  role.role_name === "ARCH_DEVELOPER";
            })?._id
          );
        })
      );
      //finding and storing current user, accessible plants and current user role
      const tempCurrentUser = archUsersList.archUsers.find((ele: any) => {
        return ele.mail === user.mailId;
      });
      const tempAccessiblePlants = tempCurrentUser?.user_arch_group
        .map((ele: any) => {
          return archUsersList.archRoles.find((role: any) => {
            return role._id === ele;
          });
        })
        .filter((role: any) => {
          return role.role_name.includes("ARCH_PM");
        })
        .map((role: any) => {
          return plantsList.data.find((plant: any) => {
            return plant.plant_code === role.role_name.substring(8);
          });
        });
      const tempUserRole = tempCurrentUser?.user_arch_group.map((ele: any) => {
        return archUsersList.archRoles.find((role: any) => {
          return role._id === ele;
        });
      });
      const payload = {
        currentUser: tempCurrentUser,
        curentUserPermissions: tempUserRole,
        accessiblePlants: tempUserRole.some((role: any) => {
          return role.role_name === "ARCH_ADMIN" ||  role.role_name === "ARCH_DEVELOPER";
        })
          ? plantsList?.data
          : tempAccessiblePlants,
      };
      //dispatch call to store user details in redux store
      dispatch(loadCurrentUser(payload));
      //selecting default plant for cube calls based on user role
      if (
        tempUserRole.some((role: any) => {
          return role.role_name === "ARCH_ADMIN" || role.role_name === "ARCH_DEVELOPER";
        })
      ) {
        setSelectedPlantForCube(plantsList?.data[0]?.plant_code);
      } else {
        setSelectedPlantForCube(tempAccessiblePlants[0]?.plant_code);
      }
    }
  }, [archUsersList.archRoles, archUsersList.archUsers]);

  return (
    <ThemeProvider>
      <AppContext.Provider
        value={{
          selectedPlantForCube,
          setSelectedPlantForCube,
        }}
      >
        <CssBaseline />
        <SessionTimeout />
        <CubeProvider cubejsApi={cubejsApi}>
          <AuthenticatedTemplate>
            {archUsersList.loading === true ? (
              <CircularProgress style={{ margin: "22% 48%", color: "white" }} />
            ) : archUsersList.curentUserPermissions.some((role: any) => {
                return role.role_name === "ARCH_ADMIN";
              }) ? (
              contentAdmin
            ) : archUsersList.curentUserPermissions.filter((role: any) => {
                return role.role_name.includes("ARCH_PM");
              }).length > 0 &&
              archUsersList.currentUser?.user_account_group?.length == 0 ? (
              contentPlantManager
            ) : archUsersList.curentUserPermissions.some((role: any) => {
                return role.role_name === "ARCH_DEVELOPER"; 
            }) ? (
              contentDeveloper 
            ) : (
              contentUnauthorized
            )}
          </AuthenticatedTemplate>
          <CustomizedSnackbars
            open={snackBarState.open}
            message={snackBarState.message}
            type={snackBarState.type}
          />
        </CubeProvider>
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default App;
