import axios from "../../config/axiosConfigArch";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  loadUsecase,
  loaduseCaseError,
  loaduseCaseSuceess,
} from "./UseCaseSlice";

//function to get all usecases from the api
const getUsecaseData = async () => {
  const response = await axios.get("/usecases");
  return response.data;
};

function* listUseCaseData(): any {
  try {
    const data = yield call(() =>
      getUsecaseData().then((res) => {
        return res;
      })
    );

    yield put({
      type: loaduseCaseSuceess.toString(),
      payload: data.rows,
    });
  } catch (error) {
    yield put({
      type: loaduseCaseError.toString(),
      payload: error,
    });
  }
}

export function* UsecaseSaga() {
  yield takeLatest(loadUsecase.toString(), listUseCaseData);
}
